<template>
  <div class="MenuConfig">
    <h2>菜单管理</h2>
    <el-button
      type="primary"
      @click="handleAdd('0')"
      :disabled="onCheckBtnAuthority('MenuConfigAdd')"
      >添加</el-button
    >
    <el-table
      :data="menuList"
      style="margin-top: 20px"
      row-key="id"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column prop="id" label="ID"> </el-table-column>
      <el-table-column prop="name" label="菜单名"> </el-table-column>
      <el-table-column prop="code" label="编码"> </el-table-column>
      <el-table-column prop="path" label="路径"> </el-table-column>
      <el-table-column prop="sort" label="排序"> </el-table-column>
      <el-table-column label="操作" align="right" width="260">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.row)" :disabled="onCheckBtnAuthority('MenuConfigEdit')">编辑</el-button>
          <el-button
            size="mini"
            type="primary"
            v-if="scope.row.category == '1'"
            @click="handleAdd(scope.row.id)"
            :disabled="onCheckBtnAuthority('MenuConfigAdd')"
            >添加</el-button
          >
          <el-button size="mini" type="danger" @click="handleDelete(scope.row)"
          :disabled="onCheckBtnAuthority('MenuConfigDel')"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      title="菜单数据"
      :visible.sync="showDialog"
      width="40%"
      :before-close="handleClose"
    >
      <el-form :model="formData" :rules="formRules" ref="ruleForm" label-width="80px">
        <el-form-item label="菜单名称" prop="name">
          <el-input v-model="formData.name"></el-input>
        </el-form-item>
        <el-form-item label="路由地址" prop="path">
          <el-input v-model="formData.path"></el-input>
        </el-form-item>
        <el-form-item label="编码" prop="code">
          <el-input v-model="formData.code"></el-input>
        </el-form-item>
        <el-form-item label="菜单类型" prop="category">
          <el-radio-group v-model="formData.category">
            <el-radio label="1">页面</el-radio>
            <el-radio label="2">按钮</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input-number
            v-model="formData.sort"
            :min="1"
            :max="99"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="formData.remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
    
<style lang="less" scoped>
.MenuConfig {
  padding: 20px;
}
</style>
    
<script>
export default {
  name: "MenuConfig",
  components: {},
  data() {
    return {
      pageBtn: [],
      menuList: [],
      showDialog: false,
      formData: {
        id: null,
        name: null,
        path: null,
        parentId: null,
        code: null,
        category: null,
        sort: null,
        remark: null,
      },
      formRules: {
        name: [{ required: true, message: "必填项", trigger: "blur" }],
        path: [{ required: true, message: "必填项", trigger: "blur" }],
        code: [{ required: true, message: "必填项", trigger: "blur" }],
        category: [{ required: true, message: "必填项", trigger: "blur" }],
        sort: [{ required: true, message: "必填项", trigger: "blur" }],
      },
    };
  },
  computed: {},
  mounted() {
    this.onGetPageBtn();
    this.onGetMenuList();
  },
  methods: {
    goto: function (path) {
      this.$router.push({
        name: path,
      });
    },
    // 获取当前页面按钮权限
    onGetPageBtn: function () {
      var that = this;
      var data = {
        id: "1680744497607729153",
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-tower/menu/buttons",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.pageBtn = res.data;
          } else {
            that.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 检查按钮权限
    onCheckBtnAuthority: function (code) {
      var disable = true;
      if (this.pageBtn.length) {
        this.pageBtn[0].children.forEach((item) => {
          if (item.code == code) {
            disable = false;
          }
        });
      }
      return disable;
    },
    // 获取菜单列表
    onGetMenuList: function () {
      var that = this;
      var data = {};
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-tower/menu/tree",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.menuList = res.data;
          } else {
            that.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 初始化formData
    formDataInit: function () {
      this.formData.id = null;
      this.formData.name = null;
      this.formData.path = null;
      this.formData.parentId = null;
      this.formData.code = null;
      this.formData.category = null;
      this.formData.sort = null;
      this.formData.remark = null;

      
    },
    // 添加菜单
    handleAdd: function (id) {
      this.formDataInit();
      this.formData.parentId = id;
      this.showDialog = true;
    },
    // 编辑菜单
    handleEdit: function (row) {
      this.formData.id = row.id;
      this.formData.name = row.name;
      this.formData.path = row.path;
      this.formData.parentId = row.parentId;
      this.formData.code = row.code;
      this.formData.category = row.category;
      this.formData.sort = row.sort;
      this.formData.remark = row.remark;

      this.showDialog = true;
    },
    // 关闭
    handleClose: function () {
      this.formDataInit();
      this.$refs['ruleForm'].resetFields();
      this.showDialog = false;
    },
    // 删除
    handleDelete: function (row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.onDelete(row.id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 执行删除
    onDelete: function (id) {
      var that = this;
      var data = {
        id: id,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-tower/menu/remove",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.onGetMenuList();
            that.reloadUserMenuData();
            that.$message({
              type: "success",
              message: "删除成功!",
            });
          } else {
            that.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 提交
    handleSubmit: function () {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.$confirm("此操作将提交该数据, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.onSubmit();
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
        }
      });
    },
    // 执行提交
    onSubmit: function () {
      var that = this;
      var data = this.formData;
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-tower/menu/submit",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.handleClose();
            that.onGetMenuList();
            that.reloadUserMenuData();
            that.$message({
              type: "success",
              message: "操作成功!",
            });
          } else {
            that.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 重新加载当前用户系统菜单
    reloadUserMenuData: function () {
      var that = this;
      var data = {};
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-tower/menu/routes",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.code == 200 && res.success == true) {
            localStorage.setItem("menuData", JSON.stringify(res.data));
            location.reload();
          } else {
            that.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
    